import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'

import CheckIcon from '../../assets/check-icon.svg'

const YoutubePlayerLite = loadable(() => import('../common/youtube-player-lite'))

const PageRemoteAccessSection = () => (
  <>
    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>EMPOWER YOUR WORKFORCE WITH MOBILITY AND BYOD</h2>
          <p className='text-lg text-gray-800'>Move to the cloud, and embrace mobility and BYOD, with SonicWall SMA. Consolidate access control for hybrid IT, federate identities for single sign-on and deliver anytime, anywhere, any-device access to network, web or cloud applications.</p>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Deliver seamless secure access experience with always-on connection</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Check integrity and health profile of the connecting device</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Get complete application access control using per-app VPN</p>
          </div>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <YoutubePlayerLite youtubeID="0ViAZ44OCoo" title="Secure Remote Access" />
        </div>
      </div>
    </div>

    <div className="bg-gray-100 w-full py-16 border-b">
      <div className='flex items-center justify-center mb-6 px-4'>
        <StaticImage
          src='../../images/services/secure-access-1.png'
          width={850}
          layout="constrained"
          alt="Secure Access"
          placeholder='tracedSVG'
          formats={["auto", "webp", "avif"]}
        />
      </div>

      <div className="w-11/12 max-w-3xl mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue font-bold mb-8'>DELIVER BEST-IN-CLASS SECURE ACCESS AND MINIMIZE SURFACE THREATS</h2>

        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>High-performance and fast SSL VPN access</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Browser-based access</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Secure access to your business network from home</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Fast and Easy setup for you</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Seamless fast and secure connection for your employees</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Multi-Factor Authentication so you know your users and your business network is secure</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Fast and low cost setup and monitoring</p>
        </div>

        <div className="flex flex-col md:flex-row items-center md:space-x-6">
          <Link to="/assessment">
            <button className="bg-transparent hover:bg-white text-red-600 border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
              Get Free Assessment
            </button>
          </Link>
          <a href="tel:9495430234">
            <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
              (949) 543-0234
            </button>
          </a>
        </div>
      </div>
    </div>

    <div className="bg-blue-100 w-full border-b py-16">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>Consolidate Access Management While Moving To The Cloud</h2>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Provide secure access to data center, cloud and SaaS resources from a single portal</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Enable federated single sign-on (SSO)</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Reduce complexity in access management for hybrid IT environments</p>
          </div>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <StaticImage
            src='../../images/services/secure-access-3.png'
            width={677}
            layout="constrained"
            alt="Secure Access"
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>

      <div className="w-11/12 max-w-screen-xl flex flex-col xl:flex-row items-center justify-center mx-auto">
        <div className='w-full lg:w-1/2 p-4'>
          <StaticImage
            src='../../images/services/secure-access-4.png'
            width={597}
            layout="constrained"
            alt="Secure Access"
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>Deploy In Private Or Public Cloud</h2>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Support virtual appliance in private cloud platforms (ESXi or Hyper-V), or in AWS or Microsoft Azure public cloud environments</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Deliver system resiliency and service reliability</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Simplify and speed provisioning and management</p>
          </div>

          <div className='flex items-start mt-6'>
            <CheckIcon className="w-8 mr-4 flex-shrink-0" />
            <p className='text-lg text-gray-800'>Scale system performance while reducing deployment cost</p>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white w-full py-16">
      <div className='flex items-center justify-center mb-6 px-4'>
        <StaticImage
          src='../../images/services/secure-access-2.png'
          width={807}
          layout="constrained"
          alt="Secure Access"
          placeholder='tracedSVG'
          formats={["auto", "webp", "avif"]}
        />
      </div>

      <div className="w-11/12 max-w-3xl mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue font-bold mb-8'>PREVENT BREACHES AND ADVANCED THREATS</h2>

        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Integrated with Capture ATP multi-engine sandboxing with patented RTDMI technologies</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Prevent malicious files from being uploaded into your network</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Protect against DDoS and zombie attacks from compromised endpoints</p>
        </div>
        <div className='flex items-start mt-6'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Enforce stacked multi-factor authentication for added security</p>
        </div>

        <div className="flex flex-col md:flex-row items-center md:space-x-6">
          <Link to="/assessment">
            <button className="bg-transparent hover:bg-white text-red-600 border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
              Get Free Assessment
            </button>
          </Link>
          <a href="tel:9495430234">
            <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
              (949) 543-0234
            </button>
          </a>
        </div>
      </div>
    </div>
  </>
)

export default PageRemoteAccessSection